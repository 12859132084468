import React from "react";
import styled from "@emotion/styled";

const Video = ({ videoSrc, rate, videoTitle = "" }) => {
  const [playbackRate, setPlaybackRate] = React.useState(1);
  const videoRef = React.useRef();

  // const setPlayBackRate = () => {
  //     videoRef.current.playbackRate = 0.3;
  // }

  React.useEffect(() => {
    const video = videoRef.current;
    video.playbackRate = playbackRate;
  }, [playbackRate]);

  React.useEffect(() => {
    videoRef.current.defaultMuted = true;
  });

  return (
    <div className={"video-banner"}>
      <Video.Video
        ref={videoRef}
        onCanPlay={() => setPlaybackRate(rate)}
        autoPlay
        muted
        loop
        playsInline
        tab-index={"-1"}
        aria-hidden={"true"}
      >
        <source src={videoSrc} type={"video/mp4"} />
      </Video.Video>
    </div>
  );
};

Video.Video = styled.video`
  min-width: 100%;
  margin: 0 auto;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: cover;
  transition: 1s opacity;
`;

export default Video;
