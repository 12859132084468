import styled from "styled-components";
import Zamarad from "../../assets/images/featured/zamarad.webp";

import Vie from "../../assets/images/featured/vie.webp";

const Featured = () => {
  return (
    <>
      <div className="">
        {/* <BrandsBanner /> */}
        <div className="container-fluid">
          <div className="row">


            <div className="col-lg-6 d-flex align-items-stretch subscribe-div">
              <div className="cta cta-box">
                <div className="cta-content">
                  <h3
                    style={{
                      fontSize: "56px",
                        }}
                  >CULTURAL LUXURY</h3>
                  <p
                    style={{
                      textAlign: "justify",
                      textJustify: "inter-character",
                      wordSpacing: "1px",
                      fontSize: "18px",
                    }}
                  >
                    OOur passion and strong culture are passed over in our products. Every single item is handcrafted in our studio by talented artisans. Yoanny Garcia is committed to the local community and has been keen in the local economy recovery.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 banner-overlay-div">
              <div className="banner banner-overlay">
                <a href="/">
                  <img src={Zamarad} alt="Banner" />
                </a>

                <div className="banner-content banner-content-center"></div>
              </div>
            </div>
          </div>
        </div>
        {/* add same inverted */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 banner-overlay-div">
              <div className="banner banner-overlay">
                <a href="/">
                  <img src={Vie} alt="Banner" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-stretch subscribe-div">
              <div className="cta cta-box">
                <div className="cta-content">
                  <h3
                    style={{
                      fontSize: "56px",
                    }}
                  >HANDMADE</h3>
                  <p
                    style={{
                      textAlign: "justify",
                      textJustify: "inter-character",
                      wordSpacing: "1px",
                      fontSize: "18px",
                    }}
                  >
                    Every designer has a unique style which is reflected in
                    their products, for Yoanny García, quality is reflected
                    through the process in which those products are
                    manufactured, where dedication and love results in pieces of
                    art, magical works that invite the user to want to be part
                    of the YG world where exclusivity becomes one of the main
                    characteristics.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Featured;
